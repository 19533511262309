import { mapActions, mapGetters } from 'vuex';
import { downloadFileFrom } from '@/helpers';

export default {
  name: 'expanded-invoice',
  props: {
    document: Object,
    type: String
  },
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters({
      documentInfo: 'documents/documentInfo',
      loading: 'documents/loading',
      file: 'documents/pdf',
      isManager: 'profile/isManager'
    })
  },
  async created() {
    await this.fetchDocument({ id: this.document.id });
  },
  methods: {
    ...mapActions({
      fetchDocument: 'documents/GET_DOCUMENT_INFO',
      fetchExcel: 'documents/GET_DOCUMENT_EXCEL'
    }),
    downloadDocument() {
      this.fetchExcel({ id: this.document.id }).then((response) => {
        downloadFileFrom(this.file.path, response.data.name + '.xls');
      });
    }
  }
};
